<template>
    <div class="preview_area show_sidebar">
        <div class="global_setting">
            <button class="close_btn" @click="closeModal()"><i class="fas fa-long-arrow-alt-left"></i></button>
            <Form v-slot="{ errors }" ref="upload-wizard-form" class="tabs_content">
                <div class="action_area">
                    <!-- <ul class="step_bar">
                        <li :class="step ? 'active' : ''" @click="selectedStepBar('1')"><span>1</span><label>Upload</label></li>
                        <li :class="step > 1 ? 'active' : ''" @click="selectedStepBar('2')"><span>2</span><label>Segment</label></li>
                        <li :class="step > 2 ? 'active' : ''" @click="selectedStepBar('3')"><span>3</span><label>Tagging</label></li>
                        <li :class="step > 3 ? 'active' : ''" @click="selectedStepBar('4')"><span>4</span><label>Fields</label></li>
                        <li :class="step > 4 ? 'active' : ''" @click="selectedStepBar('5')"><span>5</span><label>Consent</label></li>
                    </ul> -->
                    <div class="info_bar">
                        <div class="time">Estimated Time: <span>2 min.</span></div>
                        <div class="step_bar2">
                            <ul>
                                <li @click="selectedStepBar('1')" :class="{ 'active' : step }">
                                    <span>1</span>
                                    <h6>Upload</h6>
                                </li>
                                <li @click="selectedStepBar('2')" :class="{ 'active' : step > 1}">
                                    <span>2</span>
                                    <h6>Segment</h6>
                                </li>
                                <li @click="selectedStepBar('3')" :class="{ 'active' : step > 2}">
                                    <span>3</span>
                                    <h6>Tagging</h6>
                                </li>
                                <li @click="selectedStepBar('4')" :class="{ 'active' : step > 3 }">
                                    <span>4</span>
                                    <h6>Fields</h6>
                                </li>
                                <li @click="selectedStepBar('5')" :class="{ 'active' : step > 4}">
                                    <span>5</span>
                                    <h6>Consent</h6>
                                </li>
                            </ul>
                            <div class="total_bar"><span :style="`width:${(100 / 4) * (step - 1) }%`"></span></div>
                        </div>
                    </div>
                    <div v-if="step == 1">
                        <h3>Please Upload A Clean List</h3>
                        <div class="setting_wpr">
                            <div class="upload_image">
                                <label for="csv-file-box" :class="{ 'has-error': errors.file }" v-bind="getRootProps()" v-if="initializedDragAndDrop">
                                    <Field autocomplete="off" name="file" v-model="form.file" rules="required">
                                        <input name="file" v-bind="getInputProps({ ref: 'contact-csv-file', multiple: false })" type="file" change="fileSelected($event)" id="csv-file-box" ref="contact-csv-file" hidden accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
                                    </Field>
                                    <img src="@/assets/images/cloud.svg">
                                    <h4>Click here to upload</h4>
                                </label>
                                <div class="file_info">
                                    A clean list is a file that does not contain duplicate emails, previous unsubscribes, scraped emails, or emails obtained through a purchase. these will negatively affect your sender score and lower deliverability.
                                </div>
                            </div>
                            <ErrorMessage name="file" class="text-danger" />
                        </div>
                    </div>
                    <div v-if="step == 2">
                        <h3>Segment</h3>
                        <div class="fld_box">
                            <div class="setting_wpr">
                                <div class="form_grp">
                                    <div class="group_item">
                                        <label class="input_label">What primary segment should these contacts be placed in?</label>
                                        <ul class="segment">
                                            <li>
                                                <label for="leads" :class="{'active' : form.primary_segment == 'leads'}">
                                                    <input type="radio" id="leads" name="primary_segment" value="leads" v-model="form.primary_segment" hidden>
                                                    <p>Leads</p>
                                                    <span class="tick_box"></span>
                                                </label>
                                            </li>
                                            <li>
                                                <label for="trials" :class="{'active' : form.primary_segment == 'trials'}">
                                                    <input type="radio" id="trials" name="primary_segment" value="trials" v-model="form.primary_segment" hidden>
                                                    <p>Trials</p>
                                                    <span class="tick_box"></span>
                                                </label>
                                            </li>
                                            <li>
                                                <label for="clients" :class="{'active' : form.primary_segment == 'clients'}">
                                                    <input type="radio" id="clients" name="primary_segment" value="clients" v-model="form.primary_segment" hidden>
                                                    <p>Clients</p>
                                                    <span class="tick_box"></span>
                                                </label>
                                            </li>
                                            <li>
                                                <label for="staff" :class="{'active' : form.primary_segment == 'staff'}">
                                                    <input type="radio" id="staff" name="primary_segment" value="staff" v-model="form.primary_segment" hidden>
                                                    <p>Staff</p>
                                                    <span class="tick_box"></span>
                                                </label>
                                            </li>
                                            <li>
                                                <label for="lost" :class="{'active' : form.primary_segment == 'lost'}">
                                                    <input type="radio" id="lost" name="primary_segment" value="lost" v-model="form.primary_segment" hidden>
                                                    <p>Lost</p>
                                                    <span class="tick_box"></span>
                                                </label>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="form_grp">
                                    <div class="group_item">
                                        <label class="input_label">What secondary segment should these contacts be placed in?</label>
                                        <div class="field_wpr mt-2" :class="{ 'has-error': errors.status }">
                                            <Field autocomplete="off" name="status" v-model="form.status" :class="{ 'has-error': errors.status }" rules="required">
                                                <multiselect
                                                    v-model="form.status"
                                                    :searchable="true"
                                                    :options="statuses[form.primary_segment]"
                                                    valueProp="value"
                                                    label="title"
                                                    placeholder="Select segment"
                                                    :loading="statusTabsLoader"
                                                ></multiselect>
                                            </Field>
                                        </div>
                                        <ErrorMessage name="status" class="text-danger" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="step == 3">
                        <h3>Tagging</h3>
                        <div class="fld_box">
                            <div class="setting_wpr">
                                <div class="form_grp">
                                    <div class="group_item">
                                        <label class="input_label">
                                            <span>What tags (if any) would you like to add to this list?</span>
                                            <a class="option" @click="createTags = !createTags">Create a Tag</a>
                                        </label>
                                        <div class="field_wpr mt-2" :class="{ 'has-error': errors.tag }">
                                            <Field autocomplete="off" name="tag" v-model="form.tags">
                                                <multiselect
                                                    v-model="form.tags"
                                                    mode="tags"
                                                    label="name"
                                                    value-prop="id"
                                                    :searchable="true"
                                                    :group-select="true"
                                                    :options="tagsGroup"
                                                    :groups="true"
                                                    group-label="type"
                                                    group-options="tags"
                                                    :group-hide-empty="true"
                                                    placeholder="Select Tags"
                                                >
                                                    <template v-slot:grouplabel="{ group }">
                                                        <span :class="group.type.replace(' ', '-').toLowerCase()">{{ group.type }}</span>
                                                    </template>
                                                    <template v-slot:option="{ option }">
                                                        <span>{{ option.name }}</span>
                                                    </template>
                                                    <template v-slot:tag="{ option, handleTagRemove, disabled }">
                                                        <div class="multiselect-tag" :class="option.type.replace(' ', '-').toLowerCase()">
                                                        {{ option.name }}
                                                        <span
                                                            v-if="!disabled"
                                                            class="multiselect-tag-remove"
                                                            @mousedown.prevent="handleTagRemove(option, $event)"
                                                        >
                                                            <span class="multiselect-tag-remove-icon"></span>
                                                        </span>
                                                        </div>
                                                    </template>
                                                </multiselect>
                                            </Field>
                                        </div>
                                        <ErrorMessage name="tag" class="text-danger" />
                                    </div>
                                </div>
                                <div v-if="createTags" class="form_grp">
                                    <Form v-slot="{ errors }" ref="tag-form">
                                        <div class="group_item">
                                            <label class="input_label">Create a New Tag</label>
                                            <div class="field_wpr has_suffix mt-2" :class="{ 'has-error': errors.name }">
                                                <Field autocomplete="off" type="text" v-model="tagForm.name" name="name" placeholder="Enter Tag Name.." rules="required" />
                                                <span class="suffix pointer" @click="handleTagCreate"><i class="fa fa-spinner fa-spin" v-if="tagLoader"></i> {{ tagLoader ? 'Creating' : 'Create' }}</span>
                                            </div>
                                            <ErrorMessage name="name" class="text-danger" />
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="step == 4">
                        <h3>Fields</h3>
                        <div class="fld_box">
                            <div class="setting_wpr">
                                <div class="fixed_height" @scroll="scrollFunc($event)">
                                    <div class="form_grp">
                                        <div class="group_item">
                                            <label class="input_label">First name</label>
                                            <div class="field_wpr">
                                                <select v-model="fname">
                                                    <option selected="" value="">Map a column...</option>
                                                    <template v-for="(field, f) in mapFields" :key="f">
                                                        <option :disabled="selectedMap.includes(field)" :value="field" v-if="field != null">{{ field }}</option>
                                                    </template>
                                                </select>
                                                <span class="icon"><i class="fas fa-angle-down"></i></span>
                                            </div>
                                        </div>
                                        <div class="group_item">
                                            <label class="input_label">Last name</label>
                                            <div class="field_wpr">
                                                <select v-model="lname">
                                                    <option selected="" value="">Map a column...</option>
                                                    <template v-for="(field, f) in mapFields" :key="f">
                                                        <option :disabled="selectedMap.includes(field)" :value="field" v-if="field != null">{{ field }}</option>
                                                    </template>
                                                </select>
                                                <span class="icon"><i class="fas fa-angle-down"></i></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form_grp">
                                        <div class="group_item">
                                            <label class="input_label">Email</label>
                                            <div class="field_wpr">
                                                <select v-model="email">
                                                    <option selected="" value="">Map a column...</option>
                                                    <template v-for="(field, f) in mapFields" :key="f">
                                                        <option :disabled="selectedMap.includes(field)" :value="field" v-if="field != null">{{ field }}</option>
                                                    </template>
                                                </select>
                                                <span class="icon"><i class="fas fa-angle-down"></i></span>
                                            </div>
                                        </div>
                                        <div class="group_item">
                                            <label class="input_label">Phone number</label>
                                            <div class="field_wpr">
                                                <select v-model="number">
                                                    <option selected="" value="">Map a column...</option>
                                                    <template v-for="(field, f) in mapFields" :key="f">
                                                        <option :disabled="selectedMap.includes(field)" :value="field" v-if="field != null">{{ field }}</option>
                                                    </template>
                                                </select>
                                                <span class="icon"><i class="fas fa-angle-down"></i></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form_grp">
                                        <div class="group_item">
                                            <label class="input_label">Address</label>
                                            <div class="field_wpr">
                                                <select v-model="address">
                                                    <option selected="" value="">Map a column...</option>
                                                    <template v-for="(field, f) in mapFields" :key="f">
                                                        <option :disabled="selectedMap.includes(field)" :value="field" v-if="field != null">{{ field }}</option>
                                                    </template>
                                                </select>
                                                <span class="icon"><i class="fas fa-angle-down"></i></span>
                                            </div>
                                        </div>
                                        <div class="group_item">
                                            <label class="input_label">City</label>
                                            <div class="field_wpr">
                                                <select v-model="city">
                                                    <option selected="" value="">Map a column...</option>
                                                    <template v-for="(field, f) in mapFields" :key="f">
                                                        <option :disabled="selectedMap.includes(field)" :value="field" v-if="field != null">{{ field }}</option>
                                                    </template>
                                                </select>
                                                <span class="icon"><i class="fas fa-angle-down"></i></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form_grp">
                                        <div class="group_item">
                                            <label class="input_label">State</label>
                                            <div class="field_wpr">
                                                <select v-model="state">
                                                    <option selected="" value="">Map a column...</option>
                                                    <template v-for="(field, f) in mapFields" :key="f">
                                                        <option :disabled="selectedMap.includes(field)" :value="field" v-if="field != null">{{ field }}</option>
                                                    </template>
                                                </select>
                                                <span class="icon"><i class="fas fa-angle-down"></i></span>
                                            </div>
                                        </div>
                                        <div class="group_item">
                                            <label class="input_label">Country</label>
                                            <div class="field_wpr">
                                                <select v-model="country">
                                                    <option selected="" value="">Map a column...</option>
                                                    <template v-for="(field, f) in mapFields" :key="f">
                                                        <option :disabled="selectedMap.includes(field)" :value="field" v-if="field != null">{{ field }}</option>
                                                    </template>
                                                </select>
                                                <span class="icon"><i class="fas fa-angle-down"></i></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form_grp">
                                        <div class="group_item">
                                            <label class="input_label">Zip code</label>
                                            <div class="field_wpr">
                                                <select v-model="zip">
                                                    <option selected="" value="">Map a column...</option>
                                                    <template v-for="(field, f) in mapFields" :key="f">
                                                        <option :disabled="selectedMap.includes(field)" :value="field" v-if="field != null">{{ field }}</option>
                                                    </template>
                                                </select>
                                                <span class="icon"><i class="fas fa-angle-down"></i></span>
                                            </div>
                                        </div>
                                        <div class="group_item">
                                            <label class="input_label">Date of birth</label>
                                            <div class="field_wpr">
                                                <select v-model="dob">
                                                    <option selected="" value="">Map a column...</option>
                                                    <template v-for="(field, f) in mapFields" :key="f">
                                                        <option :disabled="selectedMap.includes(field)" :value="field" v-if="field != null">{{ field }}</option>
                                                    </template>
                                                </select>
                                                <span class="icon"><i class="fas fa-angle-down"></i></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form_grp">
                                        <div class="group_item">
                                            <label class="input_label">Gender</label>
                                            <div class="field_wpr">
                                                <select v-model="gender">
                                                    <option selected="" value="">Map a column...</option>
                                                    <template v-for="(field, f) in mapFields" :key="f">
                                                        <option :disabled="selectedMap.includes(field)" :value="field" v-if="field != null">{{ field }}</option>
                                                    </template>
                                                </select>
                                                <span class="icon"><i class="fas fa-angle-down"></i></span>
                                            </div>
                                        </div>
                                    </div>
                                    <h3>Custom Fields</h3>
                                    <div class="form_grp" v-for="(customFields, c) of customFieldsChunk" :key="c">
                                        <div class="group_item" v-for="(cfield, f) of customFields" :key="f">
                                            <label class="input_label">{{ cfield.name }}</label>
                                            <div class="field_wpr">
                                                <select v-model="custom_field[`field__${cfield.id}`]">
                                                    <option selected="" value="">Map a column...</option>
                                                    <template v-for="(field, f) in mapFields" :key="f">

                                                        <option :disabled="selectedMap.includes(field)" :value="field" v-if="field != null">{{ field }}</option>
                                                    </template>
                                                </select>
                                                <span class="icon"><i class="fas fa-angle-down"></i></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form_grp">
                                        <Field autocomplete="off" type="hidden" name="map_fields" v-model="selectedMap" rules="required" label="map fields" />
                                        <span v-if="errors.map_fields" role="alert" class="text-danger px-1">Please map at least one column</span>
                                    </div>
                                </div> 
                                <!-- <span :class="scrollStatus ? 'scroll_indicator swing' : 'scroll_indicator'" @click="scrolldown($event, 'fixed_height')"><i class="fas fa-long-arrow-alt-up"></i></span> -->
                            </div>
                        </div>
                    </div>
                    <div v-if="step == 5">
                        <h3>Consent</h3>
                        <div class="fld_box">
                            <div class="setting_wpr">
                                <div class="conent_terms">
                                    <label>I understand Thrive Coach is a permission-based communications platform and is not to be used with contacts who have not agreed to communicate with me through opt-in or informed consent. I also understand that my account can be terminated if I solicit, or transact with contacts who have not given me permission to contact them. If I do not have permission I agree to leave the confirmation message toggle in the ON position while completing this upload, turning it off implies I know every contact on this list and have a previous positive relationship with them.</label>
                                </div>
                                <div class="form_grp">
                                    <div class="group_item">
                                        <label for="disclaimer" class="checkbox flex-start">
                                            <input name="disclaimer" v-model="disclaimer" :true-value="1" :false-value="0" type="checkbox" id="disclaimer" hidden />
                                            <span><i class="fas fa-check"></i></span>
                                            <h5>I agree to these terms</h5>
                                        </label>
                                    </div>
                                </div>
                                <div class="form_grp mt-4">
                                    <div class="group_item">
                                        <label for="confirmed_optin" class="switch_option capsule_btn">
                                            <h5>Send with confirmed opt-in<label :style="`color: ${form.confirmed_optin ? '#2f7eed' : '#FF9041'}`">{{form.confirmed_optin ? 'ON' : 'OFF'}}</label></h5>
                                            <h5 class="ml-auto">*{{ form.confirmed_optin ? 'Safest' : 'Higher Risk' }}</h5>
                                            <input type="checkbox" id="confirmed_optin" v-model="form.confirmed_optin" :true-value="1" :false-value="0" hidden>
                                            <div><span></span></div>
                                        </label>
                                        <div class="conent_terms">
                                            <label v-if="form.confirmed_optin">A confirmation email will be sent to your contacts and they must confirm before you can transact with them.</label>
                                            <label v-else>A confirmation email not be sent to your contacts</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="action_wpr">
                        <button :disabled="loader" class="btn cancel_btn" type="button" @click="previousStep()" v-if="step !== 1">Previous</button>
                        <button :disabled="loader" class="btn save_btn" type="button" @click="nextStep()" v-if="step !== 5">{{ loader && step == 1 ? 'Verifying csv...' : 'Next' }}</button>
                        <button :disabled="loader" class="btn save_btn" type="button" @click="handleImport()" v-if="step == 5"><i class="fa fa-spinner fa-spin" v-if="loader"></i> {{ loader ? 'Importing' : 'Import' }}</button>
                    </div>
                </div>
            </Form>
        </div>
    </div>
</template>

<script>
    import { useDropzone } from 'vue3-dropzone'
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'

    import Toastr from '@/utils/Toastr'
    import Helper from '@/utils/Helper'
    import Swal from 'sweetalert2'

    export default {
        name: 'Upload Contact',

        data () {
            return  {
                step: 1,
                form: {
                    type: 'leads',
                    file: '',
                    status: '',
                    tags: [],
                    fields: [],
                    map: '',
                    array_map: '',
                    update_info: '',
                    import_status: '',
                    primary_segment: 'leads',
                    confirmed_optin: 1,
                    custom_field: {}
                },
                createTags: false,
                tagForm: {
                    name: '',
                    type: 'leads',
                    uniqueId: 'bulk-action',
                },
                selectedArrayMap: {
                    fname: '' ,
                    lname: '' ,
                    email: '' ,
                    number: '' ,
                    address: '' ,
                    city: '' ,
                    state: '' ,
                    country: '' ,
                    zip: '' ,
                    dob: '' ,
                    gender: '' ,
                    custom_field: {},
                },
                mapFields: [],
                selectedMap: [],
                fname: '',
                lname: '',
                email: '',
                number: '',
                address: '',
                city: '',
                state: '',
                country: '',
                zip: '',
                dob: '',
                gender: '',
                disclaimer: false,
                loader: false,
                stepBar: [],
                scrollStatus: true,
                getRootProps: null,
                getInputProps: null,
                initializedDragAndDrop: false,
                customFieldsChunk: [],
                custom_field: {},
            };
        },

        props: {
            closeModal: Function,
        },

        components: {
            Field,
            Form,
            ErrorMessage,
        },

        watch: {
            fname: function(n, o) {
                const vm    = this;
                const index = vm.selectedMap.indexOf(o);

                if (index !== -1) vm.selectedMap.splice(index, 1);

                vm.selectedArrayMap.fname = vm.fname;
                vm.selectedMap.push(vm.fname);
            },

            lname: function(n, o) {
                const vm    = this;
                const index = vm.selectedMap.indexOf(o);

                if (index !== -1) vm.selectedMap.splice(index, 1);

                vm.selectedArrayMap.lname = vm.lname;
                vm.selectedMap.push(vm.lname);
            },

            email: function(n, o) {
                const vm    = this;
                const index = vm.selectedMap.indexOf(o);

                if (index !== -1) vm.selectedMap.splice(index, 1);

                vm.selectedArrayMap.email = vm.email;
                vm.selectedMap.push(vm.email);
            },

            number: function(n, o) {
                const vm    = this;
                const index = vm.selectedMap.indexOf(o);

                if (index !== -1) vm.selectedMap.splice(index, 1);

                vm.selectedArrayMap.number = vm.number;
                vm.selectedMap.push(vm.number);
            },

            address: function(n, o) {
                const vm    = this;
                const index = vm.selectedMap.indexOf(o);

                if (index !== -1) vm.selectedMap.splice(index, 1);

                vm.selectedArrayMap.address = vm.address;
                vm.selectedMap.push(vm.address);
            },

            city: function(n, o) {
                const vm    = this;
                const index = vm.selectedMap.indexOf(o);

                if (index !== -1) vm.selectedMap.splice(index, 1);

                vm.selectedArrayMap.city = vm.city;
                vm.selectedMap.push(vm.city);
            },

            state: function(n, o) {
                const vm    = this;
                const index = vm.selectedMap.indexOf(o);

                if (index !== -1) vm.selectedMap.splice(index, 1);

                vm.selectedArrayMap.state = vm.state;
                vm.selectedMap.push(vm.state);
            },

            country: function(n, o) {
                const vm    = this;
                const index = vm.selectedMap.indexOf(o);

                if (index !== -1) vm.selectedMap.splice(index, 1);

                vm.selectedArrayMap.country = vm.country;
                vm.selectedMap.push(vm.country);
            },

            zip: function(n, o) {
                const vm    = this;
                const index = vm.selectedMap.indexOf(o);

                if (index !== -1) vm.selectedMap.splice(index, 1);

                vm.selectedArrayMap.zip = vm.zip;
                vm.selectedMap.push(vm.zip);
            },

            dob: function(n, o) {
                const vm    = this;
                const index = vm.selectedMap.indexOf(o);

                if (index !== -1) vm.selectedMap.splice(index, 1);

                vm.selectedArrayMap.dob = vm.dob;
                vm.selectedMap.push(vm.dob);
            },

            gender: function(n, o) {
                const vm    = this;
                const index = vm.selectedMap.indexOf(o);

                if (index !== -1) vm.selectedMap.splice(index, 1);

                vm.selectedArrayMap.gender = vm.gender;
                vm.selectedMap.push(vm.gender);
            },


            custom_field: {
                handler (n, o) {
                    const vm    = this;
                    const index = vm.selectedMap.indexOf(o);

                    if (index !== -1) vm.selectedMap.splice(index, 1);

                    vm.selectedArrayMap.custom_field = vm.custom_field;

                    Object.keys(n).forEach((k) => {
                        if (n[k]) {
                            vm.selectedMap.push(n[k]);
                        } else {
                            const i = vm.selectedMap.indexOf(o[k]);
                            if (i !== -1) vm.selectedMap.splice(i, 1);
                        }
                    });
                },
                deep: true,
            },

            step (step) {
                const vm = this;

                vm.stepBar.push(step);
            },

            customFieldsAll (fields) {
                const vm = this;

                fields.forEach((field) => {
                    vm.custom_field[`field__${field.id}`] = '';
                });

                vm.customFieldsChunk = _.chunk(_.toArray(fields), 2);
            },
        },

        computed: mapState({
            user: state => state.authModule.user,
            tagsGroup: state => state.tagModule.tagsGroup,
            tagLoader: state => state.tagModule.tagLoader,
            statusesGroup: state => state.contactModule.statusesGroup,
            statuses: state => state.contactModule.statuses,
            statusTabsLoader: state => state.contactModule.statusTabsLoader,
            customFieldsAll: state => state.contactModule.customFieldsAll,
        }),

        mounted () {
            const vm = this;

            vm.step = 1;
            vm.resetForm();
            vm.getCustomFields({ active: 1, type: 'all' });

            if (vm.tagsGroup.length == 0) {
                vm.getTags();
            }

            const { getRootProps, getInputProps  } = useDropzone({ onDrop: vm.dropzoneDrop });

            vm.getRootProps = getRootProps;
            vm.getInputProps = getInputProps;

            vm.initializedDragAndDrop = false;

            setTimeout(function () {
                vm.initializedDragAndDrop = true;
            }, 500);

            vm.customFieldsChunk = _.chunk(_.toArray(vm.customFieldsAll), 2);
            vm.customFieldsAll.forEach((field) => { vm.custom_field[`field__${field.id}`] = ''; });
        },

        methods: {
            ...mapActions({
                getTags: 'tagModule/getTags',
                createTag: 'tagModule/createTag',
                mapCSVFields: 'contactModule/mapCSVFields',
                importContact: 'contactModule/importContact',
                getCustomFields: 'contactModule/getCustomFields',
            }),

            scrollFunc (e) {
                const vm = this;
                let z = e.currentTarget;
                let a = z.clientHeight + z.scrollTop;
                let x = z.scrollHeight;

                if (a < x) {
                    vm.scrollStatus = true;
                } else {
                    vm.scrollStatus = false;
                }
            },

            scrolldown (e, el) {
                let x = e.currentTarget.closest('div');
                let elm = x.querySelector(`.${el}`);
                let z = elm.clientHeight + elm.scrollTop;

                if (z < elm.scrollHeight) {
                    elm.style.scrollBehavior = 'smooth';
                    elm.scrollTo(0, elm.scrollHeight);
                } else {
                    elm.style.scrollBehavior = 'smooth';
                    elm.scrollTo(0, 0);
                }
            },

            resetForm () {
                const vm = this;

                vm.form = {
                    type: 'leads',
                    file: '',
                    status: '',
                    tags: [],
                    fields: [],
                    map: '',
                    array_map: '',
                    update_info: '',
                    import_status: '',
                    primary_segment: 'leads',
                    confirmed_optin: 1,
                    custom_field: {},
                };

                vm.selectedArrayMap = {
                    fname: '' ,
                    lname: '' ,
                    email: '' ,
                    number: '' ,
                    address: '' ,
                    city: '' ,
                    state: '' ,
                    country: '' ,
                    zip: '' ,
                    dob: '' ,
                    gender: '' ,
                    custom_field: {},
                };

                vm.fname      = '';
                vm.lname      = '';
                vm.email      = '';
                vm.number     = '';
                vm.address    = '';
                vm.city       = '';
                vm.state      = '';
                vm.country    = '';
                vm.zip        = '';
                vm.dob        = '';
                vm.gender     = '';
                vm.disclaimer = '';

                vm.customFieldsAll.forEach((field) => {
                    vm.custom_field[`field__${field.id}`] = '';
                });
            },

            nextStep () {
                const vm = this;
                const form = vm.$refs['upload-wizard-form'];

                form.validate().then((result) => {
                    if (result.valid && vm.step != 1) {
                        vm.step += 1;
                    }
                });
            },

            previousStep () {
                const vm = this;
                vm.step -= 1;
            },

            fileSelected (event) {
                const vm = this;

                vm.stepBar = [];
                vm.form.file = event.target.files[0];

                let formData = new FormData();
                formData.append('file', vm.form.file);

                vm.loader = true;
                vm.mapCSVFields(formData).then((resp) => {
                    if (resp.data.status == 1) {
                        vm.mapFields = resp.data.response;
                        vm.step = 2;
                    } else if (resp.data.status == 0) {
                        Toastr.error(resp.data.message);
                    }

                    vm.loader = false;
                }).catch((err) => {
                    Toastr.handleServerError(err);
                    vm.loader = false;
                });
            },

            dropzoneDrop (file) {
                const vm = this;

                vm.stepBar = [];
                vm.form.file = file[0];

                let formData = new FormData();
                formData.append('file', vm.form.file);

                vm.loader = true;
                vm.mapCSVFields(formData).then((resp) => {
                    if (resp.data.status == 1) {
                        vm.mapFields = resp.data.response;
                        vm.step = 2;
                    } else if (resp.data.status == 0) {
                        Toastr.error(resp.data.message);
                    }

                    vm.loader = false;
                }).catch((err) => {
                    Toastr.handleServerError(err);
                    vm.loader = false;
                });
            },

            handleTagCreate () {
                const vm = this;
                const form = vm.$refs['tag-form'];

                form.validate().then(({ valid }) => {
                    if (valid) {
                        vm.tagForm.setFieldError = form.setFieldError;

                        vm.createTag(vm.tagForm).then((result) => {
                            vm.createTags = false;
                            vm.tagForm.name = '';
                            vm.getTags();
                        });
                    }
                });
            },

            handleImport () {
                const vm = this;

                if (vm.disclaimer) {
                    const formData = new FormData();
                    formData.append('type', vm.form.type);
                    formData.append('status', vm.form.status);
                    formData.append('map', vm.selectedMap.toString());
                    formData.append('array_map', JSON.stringify(vm.selectedArrayMap));
                    formData.append('file', vm.form.file);
                    formData.append('fields', vm.mapFields);
                    formData.append('update_info', false);
                    formData.append('tags', vm.form.tags.toString());
                    formData.append('confirmed_optin', vm.form.confirmed_optin);

                    vm.loader = true;
                    vm.importContact(formData).then((resp) => {
                        if (resp) {
                            vm.resetForm();
                            vm.closeModal();

                            const options = Helper.swalWarningOptions(`Nice work ${vm.user.fname}!`, resp.message, 'Close Wizard');
                            Swal.fire(options);
                        }

                        vm.loader = false;
                    });
                } else {
                    const options = Helper.swalWarningOptions('Disclaimer', 'Please read our disclaimer message and please check the checkbox box in order to upload your contacts!');

                    Swal.fire(options);
                }
            },

            selectedStepBar (step) {
                const vm = this;

                if (step == 1) {
                    vm.step = 1;
                } else if (step == 2 && vm.stepBar.includes(2)) {
                    vm.step = 2;
                } else if (step == 3 && vm.stepBar.includes(3)) {
                    vm.step = 3;
                } else if (step == 4 && vm.stepBar.includes(4)) {
                    vm.step = 4;
                } else if (step == 5 && vm.stepBar.includes(5)) {
                    vm.step = 5;
                }
            }
        },
    }
</script>

<style scoped>
    .add_clients h3{
        font-size: 18px;
        line-height: 25px;
        margin: 15px 0;
    }
    .action_area {
        display: flex;
        flex-direction: column;
        padding: 20px;
        margin: 20px auto 0 auto;
        max-width: 980px;
    }
    .fld_box{
        padding: 30px 40px;
        background: #fff;
        border-radius: 10px;
        border: 1px solid #f5f5f5;
        margin-bottom: 20px;
        min-height: 460px;
    }
    .fld_box .form_grp .field_wpr{
        max-width: 450px;
    }
    .file_info{
        background: #fafcff;
        border-top: 1px dashed #C0DAFF;
        padding: 20px 30px;
        border-radius: 0 0 10px 10px;
        font-size: 13px;
        line-height: 20px;
        font-weight: 300;
        color: #5a5a5a;
    }
    .conent_terms{
        background: #fbfbfb;
        padding: 20px;
        border-radius: 6px;
        margin: 5px 0;
        font-size: 14px;
        line-height: 23px;
        font-weight: 400;
        color: #5a5a5a;
    }
    label.checkbox h5{
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        color: #121525;
        margin-left: 10px;
    }
    label.checkbox span{
        margin-top: 2px;
    }
    .upload_image{
        border: 1px dashed #C0DAFF;
        border-radius: 10px;
        background: #fff;
        margin-bottom: 20px;
    }
    .upload_image label{
        border: 0;
        background: transparent;
        height: 486px;
    }
    .info_bar {
        display: flex;
        flex-direction: column;
        background: #fff;
        border: 1px solid #e9e9e9;
        border-radius: 10px;
        margin: 20px 0;
        position: sticky;
        top: 0;
        z-index: 1;
    }
    .time {
        min-width: 150px;
        font-size: 11px;
        line-height: 14px;
        font-weight: 400;
        color: #121525;
        padding: 10px 25px;
        border-bottom: 1px solid #e9e9e9;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
    }
    .time span {
        font-size: 13px;
        line-height: 16px;
        font-weight: 500;
        display: block;
    }
    .step_bar2 {
        position: relative;
        padding: 20px 30px 10px 30px;
    }
    .step_bar2 ul {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding-bottom: 25px;
        position: relative;
        z-index: 1;
    }

    .step_bar2 ul li {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
    }

    .step_bar2 ul li h6 {
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        font-size: 11px;
        line-height: 14px;
        font-weight: 500;
        color: #121525;
        white-space: nowrap;
        text-align: center;
    }

    .step_bar2 ul li:first-child h6 {
        left: 0;
        transform: none;
        text-align: left;
    }

    .step_bar2 ul li:last-child h6 {
        right: 0;
        left: auto;
        transform: none;
        text-align: right;
    }

    .step_bar2 ul li span {
        width: 28px;
        height: 28px;
        border-radius: 50%;
        background: #fff;
        border: 3px solid #e5e5e5;
        font-size: 12px;
        font-weight: 500;
        color: #2c3e50;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
    }

    .step_bar2 .total_bar {
        position: absolute;
        content: '';
        top: 32px;
        left: 30px;
        right: 30px;
        height: 2px;
        background: #d9d9d9;
    }

    .step_bar2 .total_bar span {
        background: #a1c8ff;
        position: absolute;
        left: 0;
        height: 100%;
        transition: all 0.3s ease-in-out;
    }

    .step_bar2 ul li.active span {
        background: #2f7eed;
        color: #fff;
        border-color: #a1c8ff;
    }


    .segment{
        display: flex;
        padding: 10px 0;
        gap: 7px;
    }
    .cell .segment{
        border-top: 1px solid #E6E6E6;
        display: flex;
        flex-wrap: wrap;
        padding: 20px 0 20px;
    }
    .segment li{
        padding: 4px 0;
        flex: 0 1 55px;
    }
    .segment li label{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        margin: 0;
        padding: 15px 8px 18px 8px;
        background: transparent;
        border: 1px solid transparent;
        border-radius: 5px;
        transition: all 0.3s ease-in-out;
    }
    .segment li label p{
        font-size: 13px;
        line-height: 16px;
        color: #5a5a5a;
        font-weight: 400;
    }
    .segment li label .tick_box{
        width: 14px;
        height: 14px;
        border-radius: 50%;
        border: 1px solid #5a5a5a;
        position: relative;
        margin-top: 10px;
    }
    .segment li label .tick_box:after{
        content: '';
        position: absolute;
        left: 2px;
        top: 2px;
        right: 2px;
        bottom: 2px;
        background-color: #5a5a5a;
        border-radius: 50%;
        transform: scale(0);
        transition: all 0.3s ease-in-out;
    }
    .segment li label input{
        margin-right: 7px;
        opacity: 0;
    }
    .segment li label.active{
        background: rgba(47, 126, 237, 0.05);
        border: 1px solid #b0d1ff;
    }
    .segment li label.active .tick_box:after{
        transform: scale(1);
    }
    .switch_option h5 label{
        font-weight: 600;
        padding-left: 10px;
    }
    @media(max-width: 767px){
        .global_setting .action_wpr .btn{
            padding: 0 20px;
            font-size: 13px;
            height: 40px;
        }
        .setting_wpr .group_item .input_label{
            flex-wrap: wrap;
            gap: 10px;
        }
    }
    @media(max-width: 499px){
        .action_area{
            padding: 20px 15px;
        }
        .step_bar2{
            padding: 20px 20px 10px 20px;
        }
        .fld_box{
            padding: 20px;
        }
        .fld_box .form_grp{
            flex-direction: column;
            align-items: stretch;
            gap: 24px;
            padding: 12px 0;
        }
        .fld_box .field_wpr input, .fld_box .field_wpr select{
            height: 45px;
        }
        .conent_terms{
            padding: 15px;
            font-size: 11px;
            line-height: 18px;
        }
        .file_info{
            padding: 15px 20px;
        }
    }
</style>
